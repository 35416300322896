import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Home, Map, User } from 'react-feather';

const BottomBar = () => {
    const navigate = useNavigate();

    return (
        <div style={styles.container}>
            <div style={styles.iconContainer} onClick={() => navigate('/home')}>
                <Home size={25} color="#ffffff" />
                {/* <span style={styles.text}>Home</span> */}
            </div>
            <div style={styles.iconContainer} onClick={() => navigate('/add-master-project')}>
                <Map size={20} color="#ffffff" />
                {/* <span style={styles.text}>Search</span> */}
            </div>
            <div style={styles.iconContainer} onClick={() => navigate('/profile')}>
                <User size={20} color="#ffffff" />
                {/* <span style={styles.text}>Profile</span> */}
            </div>
        </div>
    );
};

const styles = {
    container: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        backgroundColor: '#333333',
        padding: '10px 0',
        zIndex: 1000,
    },
    iconContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        cursor: 'pointer',
    },
    text: {
        color: '#ffffff',
        fontSize: '12px',
        marginTop: '4px',
    },
};

export default BottomBar;
