import React, { useContext, useEffect, useState } from 'react'
import ScreenWrapper from '../components/ScreenWrapper'
import Header from '../components/Header'
import { Download, Edit, Printer, Trash2 } from 'react-feather'
import { AuthContext } from '../context/authContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { formatCurrency, printPaymentDocument, savePDF } from '../utils/helper';
import { deleteProject, getProjectProcessStatus, getTaList, updateStatus } from '../request/request';
import { File, FileText } from 'react-feather';

function ViewProjectScreen() {

    const [state] = useContext(AuthContext);
    const currentUser = state.user;
    const location = useLocation();
    const { project } = location.state || {};
    const [talist, setTaList] = useState([]);
    const [projectProcessStatus, setProjectProcessStatus] = useState([]);
    const [selectedTa, setSelectedTa] = useState(project.ta_user_id);
    const [editable, setEditable] = useState(false);
    const [comment, setComment] = useState('');
    const [valueOfWorkDone, setValueOfWorkDone] = useState(project.value_of_work_done);
    const [valueOfStockAtDate, setValueOfStockAtDate] = useState(project.value_of_stock_at_site);
    const [totalAmount, setTotalAmount] = useState(project.total_amount);
    const [mobilizationAdvance, setMobilizationAdvance] = useState(project.mobilization_advance);
    const [scanDocs, setScanDocs] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (currentUser && currentUser.role_id === 2) {
            setEditable(true);
            const fetchProjectList = async () => {
                try {
                    const responseMasterProject = await getTaList();
                    console.log(responseMasterProject);

                    (responseMasterProject.res_code == 200) ? setTaList(responseMasterProject.data) : alert(responseMasterProject.message);
                } catch (error) {
                    console.error('Error fetching contractor list:', error);
                }
            };

            fetchProjectList();
        }
    }, [currentUser]);

    useEffect(() => {
        const fetchProjectList = async () => {
            try {
                const responseMasterProject = await getProjectProcessStatus(project.project_id);
                (responseMasterProject.res_code == 200) ? setProjectProcessStatus(responseMasterProject.data) : alert(responseMasterProject.message);
            } catch (error) {
                console.error('Error fetching contractor list:', error);
            }
        };

        fetchProjectList();

    }, [currentUser]);

    const isMob = currentUser && currentUser.role_id === 2;
    const isAuthorized = project && project.status != 1;
    const isPd = currentUser && currentUser.role_id === 4;
    const isJE = currentUser && currentUser.role_id === 1;
    const isZE = currentUser && currentUser.role_id === 2;
    const isTA = currentUser && currentUser.role_id === 3;


    const canEdit = project && project.can_edit == 1;
    const canDelete = project && project.can_delete == 1;


    const handleFilePick = (event, setFiles) => {
        const files = Array.from(event.target.files);
        setFiles(files);
    };

    const renderDocumentPreview = (documents) => {
        if (documents.length === 0) return null;

        console.log('Documents State:', documents); // Log documents state for debugging

        return documents.map((doc, index) => {
            const docType = doc.type || 'unknown'; // Handle undefined type
            const docName = doc.name || 'Untitled'; // Handle undefined name
            const docUrl = URL.createObjectURL(doc); // Create an object URL for the file

            return (
                <div key={index} style={{
                    display: 'flex',
                    alignItems: 'center',
                    border: '1px solid #ddd',
                    padding: '10px',
                    borderRadius: '5px',
                    marginBottom: '10px',
                }}>
                    {docType === 'application/pdf' ? (
                        <FileText size={50} color="red" />
                    ) : docType.startsWith('image/') ? (
                        <img src={docUrl} alt={docName} style={{
                            width: '50px',
                            height: '50px',
                            borderRadius: '5px',
                        }} />
                    ) : (
                        <File size={50} color="gray" />
                    )}
                    <span style={{
                        marginLeft: '10px',
                        fontSize: '16px',
                        flex: 1,
                        color: 'black',
                    }}>{docName}</span>
                </div>
            );
        });
    };
    const handleAction = async (action) => {
        if (comment.trim() === '') {
            // alert('Validation Error', 'Please enter a comment.');
            return;
        }
        try {
            const addTaskResponse = await updateStatus(project.project_id, currentUser.user_id, currentUser.role_id, comment, action, totalAmount, mobilizationAdvance, valueOfWorkDone, valueOfStockAtDate, selectedTa, scanDocs);
            // alert(`Project request update`, `Comment: ${comment}`);
            (addTaskResponse.res_code == 200) ? navigate('/home') : alert(addTaskResponse.message);
        } catch (error) {
            alert('Updated');
            navigate('/home');
        }
    };

    const deleteProjectclick = async (projectId) => {
        try {
            const responseMasterProject = await deleteProject(projectId);
            if (responseMasterProject.res_code == 200) {
                alert(responseMasterProject.message);
                navigate('/home');
            } else {
                alert(responseMasterProject.message);
            }
        } catch (error) {
            alert('Error', 'An error occurred while trying to delete the project.' + error);
        }
    };
    return (

        <ScreenWrapper>
            <div className='flex-1 flex flex-col'>
                <Header />
                <div className="mx-4 mt-4 mb-16">
                    <div className="bg-white p-4 rounded-2xl shadow-sm space-y-4 mb-4">
                        <div className="flex justify-between">
                            <h6 className="text-lg text-center font-bold text-primary">Payment Request Details</h6>
                            <div className="flex space-x-8 py-2">
                                {isPd && <>
                                    <button onClick={() => printPaymentDocument(project, projectProcessStatus)}>
                                        <Printer height={20} width={20} stroke='gray' />
                                    </button>
                                    <button>
                                        <Download onClick={() => printPaymentDocument(project, projectProcessStatus)} height={20} width={20} stroke='gray' />
                                    </button>
                                </>}
                                {isJE && <>
                                    {canEdit &&
                                        <button>
                                            <Edit height={20} width={20} stroke='blue' />
                                        </button>}
                                    {canDelete &&
                                        <button onClick={() => deleteProjectclick(project.project_id)}>
                                            <Trash2 height={20} width={20} stroke='red' />
                                        </button>}
                                </>}
                            </div>
                        </div>

                        <div className='space-y-1'>
                            {currentUser.role_id >= 1 && <>
                                <div className='flex'>
                                    <span className='text-sm font-bold'>A) Payment Requested by : </span>
                                    <span className='text-sm'> {project.name}</span>
                                </div>
                                <div className='flex'>
                                    <span className='text-sm font-bold'>I. Project Details : </span>
                                    <span className='text-sm'> {project.master_project_name} </span>
                                </div>
                                <div className='flex'>
                                    <span className='ml-3 text-sm'>a) Project Id : </span>
                                    <span className='text-sm'> project-{project.project_id} </span>
                                </div>
                                <div className='flex'>
                                    <span className='ml-3 text-sm'>b) Created Date & Time : </span>
                                    <span className='text-sm'> {project.create_date} {project.create_time} </span>
                                </div>
                                <div className='flex'>
                                    <span className='ml-3 text-sm'>c) Entrusting department : </span>
                                    <span className='text-sm'> {project.entrusting_department} </span>
                                </div>
                                <div className="flex-row">
                                    <span className={`ml-3 text-sm`}>d) Project completion date : </span>
                                    <span className={`text-sm`}>{project.date_completion} </span>
                                </div>
                                <div className="flex-row">
                                    <span className={`ml-3 text-sm`}>e) Stage of work : </span>
                                </div>
                                <div className="flex-row">
                                    <span className={`ml-7 text-sm`}> Nature of work : {project.project_name}</span>
                                </div>
                                <div className="flex-row">
                                    <span className={`ml-7 text-sm`}> Description of work : {project.project_description}</span>
                                </div>
                                <div className="flex-row">
                                    <span className={`ml-3 text-sm`}>f) Extimated cost : </span>
                                    <span className={`text-sm`}>{formatCurrency(project.estimated_cast)} </span>
                                </div>
                                <div className="flex-row">
                                    <span className={`ml-3 text-sm`}>g) Percentage of work done : </span>
                                    <span className={`text-sm`}>{project.percentage_of_work_done} %</span>
                                </div>
                                <div className="flex-row">
                                    <span className={`ml-3 text-sm`}>h) Value of work done : </span>
                                    <span className={`text-sm`}>{formatCurrency(project.value_of_work_done)}</span>
                                </div>
                                <div className="flex-row">
                                    <span className={`ml-3 text-sm`}>i) Value of stock at site : </span>
                                    <span className={`text-sm`}>{formatCurrency(project.value_of_stock_at_site)} </span>
                                </div>
                                <div className="flex-row">
                                    <span className={`ml-3 text-sm`}>j) Total amount : </span>
                                    <span className={`text-sm`}>{formatCurrency(project.total_amount)} </span>
                                </div>
                                <div className="flex-row">
                                    <span className={`text-sm`}>II. RA Bill : </span>
                                    <span className={`text-sm`}>Attached </span>
                                </div>
                                <div className="flex-row">
                                    <span className={`text-sm`}>III. Geo-photos : </span>
                                    <span className={`text-sm`}>Attached </span>
                                </div>
                                <div className="flex-row">
                                    <span className={`text-sm`}>IV. Self Declaration : </span>
                                </div>
                                <div className="flex-row">
                                    <span className={`ml-3 text-sm`}>1) </span>
                                    <span className={`text-sm`} style={{ width: 300 }}>I have visited the working place personally. : <span className={`text-primary text-sm font-bold`}>{project.self_declaration1} </span></span>
                                </div>
                                <div className="flex-row">
                                    <span className={`ml-3 text-sm`}>2) </span>
                                    <span className={`text-sm`} style={{ width: 300 }}>Work has been executed as per the sanctioned estimate. : <span className={`text-primary text-sm font-bold`}>{project.self_declaration2} </span></span>
                                </div>
                                <div className="flex-row">
                                    <span className={`ml-3 text-sm`}>3) </span>
                                    <span className={`text-sm`} style={{ width: 300 }}>The quality of the work done is satisfactory. : <span className={`text-primary text-sm font-bold`}>{project.self_declaration3} </span></span>
                                </div>
                                <div className="flex-row">
                                    <span className={`ml-3 text-sm`}>4) </span>
                                    <span className={`text-sm`} style={{ width: 300 }}>Material used for the construction are tested in front of me and is found satisfactory. : <span className={`text-primary text-sm font-bold`}>{project.self_declaration4} </span></span>
                                </div>
                                <div className="flex-row">
                                    <span className={`text-sm`}>V. Material Testing Report : </span>
                                    <span className={`text-sm`}>{project.mtr_doc_flag}</span>
                                </div>

                                <div className="flex-row">
                                    <span className={`ml-3 text-sm`}> Comment : </span>
                                    <span className={`text-sm`}>{project.mtr_doc_comment}</span>
                                </div>

                                <div
                                    style={{ height: 1 }}
                                    className="w-full bg-black my-2"
                                />
                            </>}
                            {currentUser.role_id >= 2 && <>
                                {/* section two ze */}
                                <div className="flex-row">
                                    <span className={`text-sm font-bold`}>B) Zonal Engineer Name : </span>
                                    <span className={`text-sm`}>{project.ze_name}</span>
                                </div>
                                <div className="flex-row">
                                    <span className={`text-sm`}>I. Mobilization Amount : </span>
                                    <span className={`text-sm`}>{project.mobilization_advance}</span>
                                </div>
                                <div className="flex-row">
                                    <span className={`text-sm`}>II. Total Amount : </span>
                                    <span className={`text-sm`}>{formatCurrency(Number(project.total_amount) + Number(project.mobilization_advance))}</span>
                                </div>
                                <div className="flex-row">
                                    <span className={`text-sm`}>III. Certification : </span>
                                    <span className={`text-sm`}>{project.JE_approve_comment}</span>
                                </div>
                                <div className="flex-row">
                                    <span className={`text-sm`}>IV. Date & time : </span>
                                    <span className={`text-sm`}>{project.JE_approve_date} {project.JE_approve_time} </span>
                                </div>

                                <div
                                    style={{ height: 1 }}
                                    className="w-full bg-black my-2"
                                />
                            </>}

                            {currentUser.role_id >= 3 && <>
                                {/* section ta */}
                                <div className="flex-row">
                                    <span className={`text-sm font-bold`}>C) Technical Assistant Name : </span>
                                    <span className={`text-sm`}>{project.ta_name}</span>
                                </div>
                                <div className="flex-row">
                                    <span className={`text-sm`}>I. Certification : </span>
                                    <span className={`text-sm`}>{project.TA_approve_comment}</span>
                                </div>
                                <div className="flex-row">
                                    <span className={`text-sm`}>II. Date & time : </span>
                                    <span className={`text-sm`}>{project.TA_approve_date} {project.TA_approve_time}</span>
                                </div>

                                <div
                                    style={{ height: 1 }}
                                    className="w-full bg-black my-2"
                                />

                            </>}

                            {currentUser.role_id >= 4 && <>
                                {/* section ta-pd */}
                                <div className="flex-row">
                                    <span className={`text-sm font-bold`}>D) Printed by TA to PD Name : </span>
                                    <span className={`text-sm`}>{project.pd_name}</span>
                                </div>
                                <div className="flex-row">
                                    <span className={`text-sm`}>I. Certification : </span>
                                    <span className={`text-sm`}>{project.TA_TD_approve_comment}</span>
                                </div>
                                <div className="flex-row">
                                    <span className={`text-sm`}>II. Date & time : </span>
                                    <span className={`text-sm`}>{project.TA_TD_approve_date} { }</span>
                                </div>

                                <div
                                    style={{ height: 1 }}
                                    className="w-full bg-black my-2"
                                />
                            </>}

                            <div className="flex">
                                <span className={`text-sm font-bold`}>Payment Process Status : </span>
                                {projectProcessStatus && projectProcessStatus.length > 0 ? (
                                    projectProcessStatus.map((projectProcess, index) => (
                                        <React.Fragment key={index}>
                                            <span className={`text-sm`}>
                                                {projectProcess.role_name} : {projectProcess.text}
                                            </span>
                                            {projectProcess.date_time_role && projectProcess.date_time_role.length > 0 ? (
                                                projectProcess.date_time_role.map((dateTimeRole, dateTimeindex) => (
                                                    <span className={`text-sm`} key={dateTimeindex}> {dateTimeRole.date_time}</span>
                                                ))
                                            ) : null}
                                        </React.Fragment>
                                    ))
                                ) : (
                                    <span className={`text-sm`}></span>
                                )}

                            </div>
                            <div
                                style={{ height: 1 }}
                                className="w-full bg-black my-2"
                            />
                            <button
                                style={{ marginTop: '20px' }}
                                className="mt-10 my-6 p-3 w-full rounded-full bg-primary"
                                onClick={() => navigate('/view-document', { state: { documents: project.documents } })}
                            >
                                <span className="text-center text-lg font-bold text-white">View Document</span>
                            </button>
                            <div
                                style={{ height: 1 }}
                                className="w-full bg-black my-2"
                            />

                        </div>
                        {isAuthorized && <>
                            {isZE && <>
                                <h6 className="text-lg font-bold">Value of Work Done : </h6>
                                <div className="mt-2 flex items-center space-x-2 pb-2">
                                    <div className="flex items-center p-3 rounded-full border border-gray-300">
                                        <input
                                            placeholder="₹"
                                            style={{
                                                border: 'none',
                                                outline: 'none',
                                                boxShadow: 'none',
                                            }}
                                            onFocus={(e) => e.target.style.outline = 'none'}
                                            onBlur={(e) => e.target.style.outline = 'none'}
                                            className="ml-2 flex-1 p-0 text-black"
                                            value={valueOfWorkDone}
                                            onChange={(e) => setValueOfWorkDone(e.target.value)} // Add onChange to manage state
                                        />
                                    </div>
                                </div>

                                <h6 className="text-lg font-bold">Value of stock at the site : </h6>
                                <div className="mt-2 flex items-center space-x-2 pb-2">
                                    <div className="flex items-center p-3 rounded-full border border-gray-300">
                                        <input
                                            placeholder="₹"
                                            style={{
                                                border: 'none',
                                                outline: 'none',
                                                boxShadow: 'none',
                                            }}
                                            onFocus={(e) => e.target.style.outline = 'none'}
                                            onBlur={(e) => e.target.style.outline = 'none'}
                                            className="ml-2 flex-1 p-0 text-black"
                                            value={valueOfStockAtDate}
                                            onChange={(e) => setValueOfStockAtDate(e.target.value)} // Add onChange to manage state
                                        />
                                    </div>
                                </div>

                                <h6 className="text-lg font-bold">Amount Total ( ₹ ) : </h6>
                                <div className="mt-2 flex items-center space-x-2 pb-2">
                                    <div className="flex items-center p-3 rounded-full border border-gray-300">
                                        <input
                                            placeholder="₹"
                                            style={{
                                                border: 'none',
                                                outline: 'none',
                                                boxShadow: 'none',
                                            }}
                                            onFocus={(e) => e.target.style.outline = 'none'}
                                            onBlur={(e) => e.target.style.outline = 'none'}
                                            className="ml-2 flex-1 p-0 text-black"
                                            value={totalAmount}
                                            onChange={(e) => setTotalAmount(e.target.value)} // Add onChange to manage state
                                        />
                                    </div>
                                </div>

                                <h6 className="text-lg font-bold">Mobilization advance ( ₹ ) : </h6>
                                <div className="mt-2 flex items-center space-x-2 pb-2">
                                    <div className="flex items-center p-3 rounded-full border border-gray-300">
                                        <input
                                            placeholder="₹"
                                            style={{
                                                border: 'none',
                                                outline: 'none',
                                                boxShadow: 'none',
                                            }}
                                            onFocus={(e) => e.target.style.outline = 'none'}
                                            onBlur={(e) => e.target.style.outline = 'none'}
                                            className="ml-2 flex-1 p-0 text-black"
                                            value={mobilizationAdvance}
                                            onChange={(e) => setMobilizationAdvance(e.target.value)} // Add onChange to manage state
                                        />
                                    </div>
                                </div>

                                <h6 className="text-lg font-bold">Select Technical Assistant</h6>
                                <div className="mt-2 flex items-center space-x-2 pb-2">
                                    <div className="flex items-center p-3 rounded-full border border-gray-300">
                                        <select
                                            value={selectedTa}
                                            onChange={(event) => {
                                                setSelectedTa(event.target.value);
                                            }}
                                            className="appearance-none bg-transparent border-none outline-none text-gray-700"
                                            style={{
                                                width: '200px',
                                                fontSize: '16px',
                                                borderRadius: '9999px',
                                                boxShadow: 'none',
                                                backgroundColor: 'transparent',
                                                padding: '0 1rem',
                                                backgroundColor: '#ffffff',
                                            }}
                                        >
                                            <option value="" disabled selected>Select TA</option>
                                            {talist.map(z => (
                                                <option key={z.user_id} value={z.user_id}>
                                                    {z.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </>}
                            {isPd && <>
                                <h6 className="text-lg font-bold">Upload Scan Doc</h6>
                                <div className="mt-2 flex items-center space-x-2 pb-2">
                                    <div className="relative flex items-center px-3 py-1 rounded-full border border-gray-300 bg-white">
                                        <input
                                            type="file"
                                            id="file-input"
                                            onChange={(e) => handleFilePick(e, setScanDocs)}
                                            className="absolute inset-0 opacity-0 cursor-pointer"
                                            multiple
                                        />
                                        <label
                                            htmlFor="file-input"
                                            className="flex items-center justify-center w-full h-10 px-4  text-black cursor-pointer"
                                        >
                                            {/* fileName || */}
                                            {'Upload Material Testing Report'}
                                        </label>
                                    </div>
                                </div>
                                {renderDocumentPreview(scanDocs)}
                            </>}

                            {isPd ? <>
                                <div className="flex justify-between mt-4">
                                    <button
                                        className='bg-primary my-6 p-3 w-full rounded-full'
                                        onPress={() => handleAction(1)}
                                    >
                                        <span className="text-center text-lg font-bold text-white">Upload</span>
                                    </button>
                                </div>
                            </> : <>
                                <h6 className="text-lg font-bold">Add Comment</h6>
                                <div className="mt-2 flex items-center space-x-2 pb-2">
                                    <div className="flex items-center p-3 border border-gray-300">
                                        <textarea
                                            placeholder="comments"
                                            style={{
                                                border: 'none',
                                                outline: 'none',
                                                boxShadow: 'none',
                                                resize: 'none',
                                            }}
                                            onFocus={(e) => e.target.style.outline = 'none'}
                                            onBlur={(e) => e.target.style.outline = 'none'}
                                            className="ml-2 flex-1 p-0 text-black"
                                            value={comment}
                                            onChange={(e) => setComment(e.target.value)}
                                            rows={3}
                                        />
                                    </div>
                                </div>

                                <div className="flex justify-between mt-4">
                                    <button
                                        className='bg-primary my-6 p-3 w-full rounded-full'
                                        onPress={() => handleAction(1)}
                                    >
                                        <span className="text-center text-lg font-bold text-white">Approve</span>
                                    </button>
                                    <button
                                        style={{ backgroundColor: 'red' }}
                                        className='bg-primary my-6 p-3 w-full rounded-full'
                                        onPress={() => handleAction(0)}
                                    >
                                        <span className="text-center text-lg font-bold text-white">Reject</span>
                                    </button>
                                </div>
                            </>}

                        </>}

                    </div>
                    {/* divProjectScreen */}
                </div>
            </div>
        </ScreenWrapper>
    )
}

export default ViewProjectScreen