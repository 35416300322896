import './tailwind.css';
import { AuthProvider } from './context/authContext'; // Import AuthProvider
import AppRoutes from './routes/AppRoutes';

function App() {
  return (
    <AuthProvider>
      <AppRoutes />
    </AuthProvider>
  );
}

export default App;
