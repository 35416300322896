// Switch.js
import React from 'react';

const Switch = ({ value, onChange }) => {
    return (
        <div
            className={`relative inline-flex items-center border-2 border-gray-400 rounded-full cursor-pointer ${value ? 'bg-blue-500' : 'bg-gray-300'}`}
            onClick={() => onChange(!value)}
        >
            <span
                className={`block w-10 h-6 bg-gray rounded-full shadow-inner transform transition-transform duration-300 ease-in-out ${value ? 'translate-x-4' : 'translate-x-0'}`}
            />
            <span
                className={`absolute left-1 top-1 w-4 h-4 bg-white rounded-full shadow transform transition-transform duration-300 ease-in-out ${value ? 'translate-x-4' : 'translate-x-0'}`}
            />
        </div>
    );
};

export default Switch;
