import React, { useContext } from 'react'
import { useLocation } from 'react-router-dom';
import { AuthContext } from '../context/authContext';
import ScreenWrapper from '../components/ScreenWrapper';
import Header from '../components/Header';
import { Download, File, FileText } from 'react-feather';

const API_URL = "https://khsc.payplatter.in/document/";

function ViewImage() {

    const [state] = useContext(AuthContext);
    const currentUser = state.user;
    const location = useLocation();
    const { documents } = location.state || [];

    const getFileExtension = (uri) => {
        console.log(uri);

        const parts = uri.split('.');
        return parts.length > 1 ? parts.pop().toLowerCase() : '';
    };

    const renderFilePreview = (fileUri, fileName) => {
        console.log(fileUri);

        const fileExtension = getFileExtension(fileName);
        let iconComponent;
        let fileNameComponent;

        switch (fileExtension) {
            case 'png':
            case 'jpg':
            case 'jpeg':
                iconComponent = <img src={fileUri} alt={fileName} style={{ width: 300, height: 200, borderRadius: 8, marginRight: 8 }} />;
                break;
            case 'pdf':
                iconComponent = <FileText size={40} color="red" />;
                fileNameComponent =
                    <span style={{ marginLeft: 8, color: 'black', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 260 }}>
                        {fileName}
                    </span>;
                break;
            case 'doc':
            case 'docx':
                iconComponent = <FileText size={40} color="blue" />;
                fileNameComponent =
                    <span style={{ marginLeft: 8, color: 'black', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 260 }}>
                        {fileName}
                    </span>;
                break;
            default:
                iconComponent = <File size={40} color="gray" />;
                fileNameComponent =
                    <span style={{ marginLeft: 8, color: 'black', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 260 }}>
                        {fileName}
                    </span>;
                break;
        }

        return (
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                {iconComponent}
                {fileNameComponent}
            </div>
        );
    };

    return (
        <ScreenWrapper>
            <div className='flex-1 flex flex-col'>
                <Header />
                <div className="mx-4 mt-4 mb-16">
                    {documents.map((doc, index) => (
                        <div className="bg-white p-4 rounded-lg shadow-md mb-4 mx-2 " style={{ maxWidth: 400 }}>
                            <div className="flex-col">
                                <h6 className="text-lg font-bold text-black">{doc.document_type_name}</h6>
                                {doc.records.map((record, index) => (

                                    <div className='flex justify-center items-center my-2'>
                                        {renderFilePreview(API_URL + record.doc_name, record.doc_name)}

                                        <a href={`${API_URL}/${record.doc_name}`} target='blank' download>
                                            <Download size={20} stroke='gray' />
                                        </a>

                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </ScreenWrapper >
    )
}

export default ViewImage