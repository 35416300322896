export function printPaymentRequest(project, projectProcessStatus) {

    const formatCurrency = (amount) => {
        return new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR',
            currencyDisplay: 'symbol',
        }).format(amount);
    };

    const convertDateString = (dateString, locale = 'en-GB', timezone = 'Asia/Tokyo') => {
        console.log(dateString);
        if (!dateString) {
            return 'N/A'; // Return "N/A" if date is null, undefined, or blank
        }

        // Parse the date string into a Date object
        const date = new Date(dateString);

        // Define options for formatting the date
        const options = {
            weekday: 'short',
            month: 'short',
            day: 'numeric',
            year: 'numeric',
            timeZone: timezone // Specify the timezone
        };

        // Format the date using Intl.DateTimeFormat with the specified locale and options
        return new Intl.DateTimeFormat(locale, options).format(date);
    };

    return `<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>KSHC</title>
    <style>
        body {
            font-family: 'Arial', sans-serif;
            margin: 0;
            padding: 0;
            background: #fff;
        }

        .receipt {
            max-width: 800px;
            padding: 20px;
        }

        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 2px solid #007bff;
            padding-bottom: 20px;
            margin-bottom: 10px;
        }

        .header .logo {
            flex: 1;
            text-align: center;
        }

        .header .logo img {
            max-width: 120px;
        }

        .header .info {
            flex: 2;
            text-align: center;
        }

        .header .info h1 {
            font-size: 22px;
            color: #007bff;
            margin: 0;
        }

        .header .info p {
            font-size: 14px;
            color: #555;
            margin: 5px 0;
        }

        .section {
            margin-bottom: 20px;
        }

        .section h2 {
            font-size: 18px;
            color: #333;
            background-color: #f8f9fa;
            padding: 5;
            border-radius: 5px;
            margin: 0;
        }

        .details,
        .documents {
            padding: 15px;
            /* border: 1px solid #ddd; */
            /* border-radius: 8px; */
            /* background-color: #f9f9f9; */
        }

        .details p,
        .documents p {
            font-size: 14px;
            margin: 5px 0;
            color: #333;
        }

        .documents img {
            max-width: 120px;
            margin: 5px;
            border: 1px solid #ddd;
            border-radius: 4px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }

        .self-declaration {
            margin-bottom: 10px;
            padding: 10px;
            background-color: #e9ecef;
            border-radius: 5px;
            border: 1px solid #ddd;
        }

        .self-declaration p {
            margin: 5px 0;
            color: #333;
        }

        .footer {
            text-align: center;
            font-size: 12px;
            color: #777;
            margin-top: 20px;
            border-top: 1px solid #ddd;
            padding-top: 10px;
        }
    </style>
</head>

<body>
    <div class="receipt">
        <div class="header">
             <div class="logo">
                 <img src="http://103.151.107.25/ContractedProjectAPI/assets/web_icon.png" alt="Logo"> <!-- Replace with your logo -->
             </div>
             <div class="info">
                 <h1>KARNATAKA STATE HABITAT CENTRE</h1>
                 <p>(Society established by Rajiv Gandhi Rural Housing Corporation Ltd, Bangalore and Registered under Karnataka societies registration Act 1960)</p>
                 <p>Cauvery Bhavan, 8th Floor , E & F Block , K.G. Road Bangalore</p>
             </div>
         </div>

        <div class="section">
            <div class="details">

                <span style="text-decoration: underline;">Note#1</span>
                <p><strong>A) Payment Requested by JE/AE</p>
                <div style="margin-left: 20px;">
                    <p style="font-weight: 400;">I. Project Details: ${project.master_project_name}</p>
                    <p style="font-weight: 400; margin-left: 10px;">a) Project ID: project-${project.project_id}</p>
                    <p style="font-weight: 400; margin-left: 10px;">b) Created date & time: ${convertDateString(project.create_date) + " " + project.create_time}</p>
                    <p style="font-weight: 400; margin-left: 10px;">c) Entrusting department: ${project.entrusting_department}</p>
                    <p style="font-weight: 400; margin-left: 10px;">d) Project completion date : ${convertDateString(project.date_completion)}</p>
                    <p style="font-weight: 400; margin-left: 10px;">e) Stage of work : <br>Nature of work - ${project.project_name}<br>
                    Description of work - ${project.project_description}
                    </p>
                    <p style="font-weight: 400; margin-left: 10px;">f) Estimated cost: ${project.estimated_cast}/-</p>
                    <p style="font-weight: 400; margin-left: 10px;">g) Percentage of work done: ${project.percentage_of_work_done} %</p>
                    <p style="font-weight: 400; margin-left: 10px;">h) Value of work done: ${formatCurrency(project.value_of_work_done)}/-</p>
                    <p style="font-weight: 400; margin-left: 10px;">i) Value of stock at site: ${formatCurrency(project.value_of_stock_at_site)}/-</p>
                    <p style="font-weight: 400; margin-left: 10px;">j) Total amount: ${formatCurrency(project.total_amount)}/-</p>

                    <p style="font-weight: 400;">II. RA Bill: Attached</p>
                    <p style="font-weight: 400;">III. Geo-photos: Attached</p>

                    <p style="font-weight: 400;">IV. Self Declaration by JE/AE Name: ${project.name}</p>
                    <p style="font-weight: 400; margin-left: 10px;">1) I have visited the working place personally. : ${project.self_declaration1}
                    </p>
                    <p style="font-weight: 400; margin-left: 10px;">2) Work has been executed as per the sanctioned
                        estimate. : ${project.self_declaration2}</p>
                    <p style="font-weight: 400; margin-left: 10px;">3) The quality of the work done is satisfactory. : ${project.self_declaration3}
                    </p>
                    <p style="font-weight: 400; margin-left: 10px;">4) Material used for the construction are tested in
                        front of me and is found satisfactory : ${project.self_declaration4}</p>

                    <p style="font-weight: 400;">V. Material Testing Report: ${project.mtr_doc_flag} <br>${project.mtr_doc_comment}</p>
                    <div style="margin: 20px; display:flex; justify-content:space-between">
                        <p style="font-weight: 400;">${convertDateString(project.create_date) + " " + project.create_time}</p>
                        <p style="font-weight: 400;">${project.name}<br>Junior Engineer</p>
                    </div>

                </div>

                <br>
                <hr>
                <br>
                
                <!-- secont doc -->
                <span style="text-decoration: underline; font-weight: 400;">Note#2</span>
                <div style="margin-left: 20px;">
                    <p style="font-weight: 400;">Mobilization Amount: ${formatCurrency(project.mobilization_advance)}/-</p>
                    <p style="font-weight: 400;">Total Amount: ${formatCurrency(Number(project.mobilization_advance) + Number(project.total_amount))}/-</p>
                    <p style="font-weight: 400;">${project.ZE_approve_comment}</p>
                </div>
                <div style="margin: 20px; display:flex; justify-content:space-between">
                    <p style="font-weight: 400;">${convertDateString(project.ZE_approve_date) + " " + project.ZE_approve_time}</p>
                    <p style="font-weight: 400;">${project.ze_name}<br>Zonal Engineer</p>
                </div>

                <br>
                <hr>
                <br>

                <!-- third doc -->
                <span style="text-decoration: underline; font-weight: 400;">Note#3</span>
                <div style="margin-left: 20px;">
                    <p style="font-weight: 400;">Status : Approved</p>
                    <p style="font-weight: 400;">${project.TA_approve_comment}</p>
                </div>
                <div style="margin: 20px; display:flex; justify-content:space-between">
                    <p style="font-weight: 400;">${convertDateString(project.TA_approve_date) + " " + project.TA_approve_time}</p>
                    <p style="font-weight: 400;"></p>
                    <p style="font-weight: 400;">${project.ta_name}<br>Technical Assistant</p>
                </div>
                <br>
                <hr>
                <br>
                <!-- fourth doc -->
                <span style="text-decoration: underline; font-weight: 400;">Note#4</span>
                <!-- <div style="margin: 20px; display:flex; justify-content:space-between">
                    <p style="font-weight: 400;">Date & time ${convertDateString(project.TA_TD_approve_date) + " " + project.TA_TD_approve_time}</p>
                    <p style="font-weight: 400;">${project.pd_name}<br>TA to PD</p>
                </div> -->
            </div>
        </div>
    </div>
</body>

</html>`;
}