import { printPaymentRequest } from '../print/paymentRequest';

const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR',
        currencyDisplay: 'symbol',
    }).format(amount);
};

function getInitials(fullName) {
    const nameParts = fullName.split(' ');
    const initials = nameParts.map(part => part[0].toUpperCase()).join('');
    return initials;
}
function printPaymentDocument(project, projectProcessStatus) {
    const htmlContent = printPaymentRequest(project, projectProcessStatus);

    // Create a new window
    const printWindow = window.open('', '', 'height=600,width=800');

    // Write the HTML content to the new window
    printWindow.document.write(htmlContent);

    // Close the document to finish writing
    printWindow.document.close();

    // Print the document
    printWindow.print();
}

export { formatCurrency, getInitials, printPaymentDocument }