import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Update import
import ScreenWrapper from '../components/ScreenWrapper';
import { Eye, Lock, User } from 'react-feather';
import { AuthContext } from '../context/authContext';
import axios from 'axios';

function LoginScreen() {
  const [username, setUsername] = useState('mohinitest@gmail.com');
  const [password, setPassword] = useState('pass@123');
  const [passVisible, setPassVisible] = useState(true);
  const navigate = useNavigate(); // Update to useNavigate
  const [state, setState] = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      if (!username || !password) {
        alert('Please fill all fields');
        setLoading(false);
        return;
      }
      const { data } = await axios.post('/login.php', {
        user_name: username,
        password: password
      });

      if (data.res_code === '200') {
        const userData = data.data[0];
        setState({
          user: userData,
          token: userData.token || '' // Ensure token is set
        });
        localStorage.setItem('auth', JSON.stringify(userData)); // Store user info in local storage
        alert(data.message);
        navigate('/home'); // Navigate to home
      } else {
        alert('Invalid Password');
      }
    } catch (error) {
      alert(error.response?.data?.message || 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  const getLocalStorageData = () => {
    const data = localStorage.getItem('auth');
    console.log('Local Storage ==> ', data);
  };
  getLocalStorageData();
  return (
    <ScreenWrapper>
      <div className="flex items-center justify-center h-full mx-4">
        <div className="p-5">
          <div className="flex justify-center my-8 mt-20">
            <img
              src='/assets/icon/web_icon.png'
              className="w-36 h-36 mb-2"
              alt="Web Icon"
            />
          </div>

          <h6 className="text-lg font-bold">username</h6>
          <div className="mt-2 flex items-center space-x-2 pb-2">
            <div className="flex items-center p-3 rounded-full border border-gray-300">
              <User height={25} width={25} stroke={'gray'} />
              <input
                placeholder="username or email"
                placeholderTextColor="#BFDBFE"
                style={{
                  border: 'none',
                  outline: 'none',
                  boxShadow: 'none',
                }}
                onFocus={(e) => e.target.style.outline = 'none'}
                onBlur={(e) => e.target.style.outline = 'none'}
                className="ml-2 flex-1 p-0 text-black"
                value={username}
                onChange={(e) => setUsername(e.target.value)} // Add onChange to manage state
              />
            </div>
          </div>

          <div className="mt-2 flex items-center space-x-2 pb-2">
            <div className="flex items-center p-3 rounded-full border border-gray-300">
              <Lock height={25} width={25} stroke={'gray'} />
              <input
                placeholder="password"
                type={passVisible ? 'text' : 'password'} // Toggle visibility
                style={{
                  border: 'none',
                  outline: 'none',
                  boxShadow: 'none',
                }}
                onFocus={(e) => e.target.style.outline = 'none'}
                onBlur={(e) => e.target.style.outline = 'none'}
                className="ml-2 flex-1 p-0 text-black"
                value={password}
                onChange={(e) => setPassword(e.target.value)} // Add onChange to manage state
              />
              <div className="flex-row items-center space-x-1">
                <button
                  onClick={() => setPassVisible(!passVisible)} // Toggle password visibility
                >
                  <Eye height={20} width={20} stroke={'gray'} className="mr-2" />
                </button>
              </div>
            </div>
          </div>

          <button className="flex justify-end w-full">
            <p className="text-black">Forgot Password?</p>
          </button>

          <div>
            <button
              className="my-6 p-3 w-full rounded-full bg-primary"
              onClick={handleSubmit} // Add onClick to trigger handleSubmit
            >
              <span className="text-center text-lg font-bold text-white">Sign In</span>
            </button>
          </div>
        </div>
      </div>
    </ScreenWrapper>
  );
}

export default LoginScreen;
