import axios from 'axios';
import React, { createContext, useState, useEffect } from 'react';

// Create a context with default values
export const AuthContext = createContext({
    user: null,
    token: '',
    setState: () => {}
});

export const AuthProvider = ({ children }) => {
    const [state, setState] = useState({
        user: null,
        token: ''
    });

    // Load user from local storage on component mount
    useEffect(() => {
        const loadUser = async () => {
            const storedUser = JSON.parse(localStorage.getItem('auth'));
            if (storedUser) {
                setState(prevState => ({
                    ...prevState,
                    user: storedUser
                }));
            }
        };
        loadUser();
    }, []);

    // Update axios default settings whenever the token changes
    useEffect(() => {
        if (state.token) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${state.token}`;
        } else {
            delete axios.defaults.headers.common['Authorization'];
        }
        axios.defaults.baseURL = 'https://khsc.payplatter.in/';
    }, [state.token]);

    return (
        <AuthContext.Provider value={[state, setState]}>
            {children}
        </AuthContext.Provider>
    );
};
