import React, { useState, useEffect, useContext } from 'react';
import { User } from 'react-feather';
import { AuthContext } from '../context/authContext';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import ScreenWrapper from '../components/ScreenWrapper';
import BottomBar from '../components/BottomBar';

export default function ProfileScreen() {
    // const { auth, setcurrentUser, logout } = useAuth();
    const [state, setState] = useContext(AuthContext);
    const currentUser = state.user;
    const navigate = useNavigate();

    // Set state with user data from auth context
    const [username, setUsername] = useState(currentUser?.user_name || '');
    const [role, setRole] = useState(currentUser?.role_name || '');
    const [name, setName] = useState(currentUser?.name || '');
    const [phone, setPhone] = useState(''); // Placeholder if phone is not available
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // You can fetch the latest user details if needed
        // fetchUserDetails();
    }, []);

    const handleUpdateProfile = async () => {
        setLoading(true);
        // try {
        //     const updatedProfile = { username, role, name, phone };
        //     // Assuming updateProfile function updates the profile and returns updated data
        //     const response = await updateProfile(updatedProfile);
        //     if (response.res_code === 200) {
        //         setcurrentUser(response.data); // Update user context
        //         Alert.alert('Profile updated successfully!');
        //     } else {
        //         Alert.alert('Error updating profile:', response.message);
        //     }
        // } catch (error) {
        //     Alert.alert('Error:', error.message);
        // } finally {
        //     setLoading(false);
        // }
    };


    const handleLogout = async () => {
        try {
            setState({ token: "", user: null });
            localStorage.removeItem('auth');
            alert("Logged out successfully");
            navigate('/login');
        } catch (error) {
            alert('An error occurred during logout');
        }
    };

    return (
        <ScreenWrapper>
            <div className="flex-1">
                <Header />
                <div className="flex-1 mt-5 mb-16 p-4 bg-white rounded-xl shadow-md">
                    <div className="flex-row justify-center mt-4">
                        <img src="/assets/profile.png" alt="Project"
                            className="w-24 h-24 rounded-full" />
                    </div>

                    <div className="mt-2">
                        <h6 className="text-lg font-bold">Name</h6>
                        <div className="mt-2 flex items-center space-x-2 pb-2">
                            <div className="flex items-center p-3 rounded-full border border-gray-300">
                                <User height={25} width={25} stroke={'gray'} />
                                <input
                                    placeholder="username or email"
                                    placeholderTextColor="#BFDBFE"
                                    style={{
                                        border: 'none',
                                        outline: 'none',
                                        boxShadow: 'none',
                                    }}
                                    onFocus={(e) => e.target.style.outline = 'none'}
                                    onBlur={(e) => e.target.style.outline = 'none'}
                                    className="ml-2 flex-1 p-0 text-black"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)} // Add onChange to manage state
                                />
                            </div>
                        </div>
                    </div>

                    <div className="mt-2">
                        <h6 className="text-lg font-bold">Name</h6>
                        <div className="mt-2 flex items-center space-x-2 pb-2">
                            <div className="flex items-center p-3 rounded-full border border-gray-300">
                                <User height={25} width={25} stroke={'gray'} />
                                <input
                                    placeholder="username or email"
                                    placeholderTextColor="#BFDBFE"
                                    style={{
                                        border: 'none',
                                        outline: 'none',
                                        boxShadow: 'none',
                                    }}
                                    onFocus={(e) => e.target.style.outline = 'none'}
                                    onBlur={(e) => e.target.style.outline = 'none'}
                                    className="ml-2 flex-1 p-0 text-black"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)} // Add onChange to manage state
                                />
                            </div>
                        </div>
                    </div>

                    <div className="mt-2">
                        <h6 className="text-lg font-bold">Role</h6>
                        <div className="mt-2 flex items-center space-x-2 pb-2">
                            <div className="flex items-center p-3 rounded-full border border-gray-300">
                                <User height={25} width={25} stroke={'gray'} />
                                <input
                                    placeholder="username or email"
                                    placeholderTextColor="#BFDBFE"
                                    style={{
                                        border: 'none',
                                        outline: 'none',
                                        boxShadow: 'none',
                                    }}
                                    onFocus={(e) => e.target.style.outline = 'none'}
                                    onBlur={(e) => e.target.style.outline = 'none'}
                                    className="ml-2 flex-1 p-0 text-black"
                                    value={role}
                                    onChange={(e) => setRole(e.target.value)} // Add onChange to manage state
                                />
                            </div>
                        </div>
                    </div>

                    <div className="mt-2">
                        <h6 className="text-lg font-bold">Name</h6>
                        <div className="mt-2 flex items-center space-x-2 pb-2">
                            <div className="flex items-center p-3 rounded-full border border-gray-300">
                                <User height={25} width={25} stroke={'gray'} />
                                <input
                                    placeholder="username or email"
                                    placeholderTextColor="#BFDBFE"
                                    style={{
                                        border: 'none',
                                        outline: 'none',
                                        boxShadow: 'none',
                                    }}
                                    onFocus={(e) => e.target.style.outline = 'none'}
                                    onBlur={(e) => e.target.style.outline = 'none'}
                                    className="ml-2 flex-1 p-0 text-black"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)} // Add onChange to manage state
                                />
                            </div>
                        </div>
                    </div>

                    {/* <TouchableOpacity
                            style={{ backgroundColor: colors.Button, marginTop: 20 }}
                            className="p-3 rounded-full"
                            onPress={handleUpdateProfile}
                            disabled={loading}
                        >
                            <Text className="text-center text-lg font-bold text-white">{loading ? 'Updating...' : 'Update Profile'}</Text>
                        </TouchableOpacity> */}

                    <button
                        style={{ marginTop: 10 }}
                        className="bg-primary py-3 rounded-full  px-16"
                        onClick={handleLogout}
                    >
                        <span className="text-center text-lg font-bold text-white">Logout</span>
                    </button>
                </div>
                <BottomBar />
            </div>
        </ScreenWrapper>
    );
}
