import React, { useContext } from 'react';
import { Smile } from 'react-feather';
import { getInitials } from '../utils/helper';
import { AuthContext } from '../context/authContext';

function Header() {
    const [states] = useContext(AuthContext);
    const currentUser = states.user;
    return (
        <div className="flex items-center space-x-2 p-2 bg-primary">
            <div className="flex items-center space-x-2 flex-1">
                <Smile height={20} width={20} stroke={'gray'} />
                <h6 className="text-gray-600 text-md font-bold">
                    {currentUser ? `Hello, ${currentUser.name || 'User'}` : 'Loading...'}
                    {currentUser && ` (${currentUser.role_name || 'User Profile'})`}</h6>
            </div>

            <div className="bg-white rounded-full border border-gray-300 p-2">
                <h6 className="font-bold text-md uppercase">
                    {currentUser ? `${getInitials(currentUser.role_name)}` : 'Loading...'}
                </h6>
            </div>
        </div>
    );
}

export default Header;
