// Checkbox.js
import React from 'react';

const Checkbox = ({ label, checked, onChange }) => {
  return (
    <div
      onClick={() => onChange(!checked)}
      className="flex items-center cursor-pointer my-2"
    >
      <div
        className={`w-6 h-6 border-2 border-gray-400 rounded-lg flex items-center justify-center ${checked ? 'bg-blue-500' : 'bg-white'
          }`}
      >
        {checked && (
          <span className="text-white text-lg font-bold">✓</span>
        )}
      </div>
      <span style={{ maxWidth: '300px' }} className="ml-2 text-black text-sm">{label}</span>
    </div>
  );
};

export default Checkbox;
