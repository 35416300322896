import axios from 'axios';

const API_URL = "https://khsc.payplatter.in/";
// const API_URL = "https://testkhsc.payplatter.in/get_project.php";
const PROJECT_URL = `${API_URL}get_project.php`;
const GET_TASK_TYPE = `${API_URL}get_task_type.php`;
const ADD_PROJECT_URL = `${API_URL}add_project.php`;
const UPDATE_PROJECT_URL = `${API_URL}update_project.php`;
const ADD_CONTRACTOR_URL = `${API_URL}get_contractor.php`;
const GET_MASTER_PROJECT_URL = `${API_URL}get_master_project.php`;
const PUT_PROJECT_STATUS_URL = `${API_URL}add_project_status_new.php`;

const mimeTypeMap = {
    'pdf': 'application/pdf',
    'jpg': 'image/jpeg',
    'jpeg': 'image/jpeg',
    'png': 'image/png',
    'gif': 'image/gif',
    'txt': 'text/plain',
    'csv': 'text/csv',
    'doc': 'application/msword',
    'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'xls': 'application/vnd.ms-excel',
    'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    // Add more MIME types as needed
};

// Function to get MIME type from file extension
const getMimeType = (fileUri) => {
    const extension = fileUri.split('.').pop().toLowerCase();
    return mimeTypeMap[extension] || 'application/octet-stream';  // Default MIME type
};

function getFileName(fileUri) {
    if (typeof fileUri !== 'string') {
        throw new Error('fileUri should be a string');
    }
    return fileUri.split('/').pop(); // Adjust according to your URI structure
}

const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so +1
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
};

async function getProject(role_id, user_id) {
    const payload = {
        role_id: role_id,
        user_id: user_id
    };
    try {
        const response = await axios.post(PROJECT_URL, payload, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error during login:', error);
        throw error;
    }
}

async function getTaskType() {
    const payload = {
        role_id: 1,
        user_id: 1
    };

    try {
        const response = await axios.post(GET_TASK_TYPE, payload, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error during login:', error);
        throw error;
    }
}


async function getContractorList() {
    const payload = {
        role_id: 1,
        user_id: 1
    };

    try {
        const response = await axios.post(ADD_CONTRACTOR_URL, payload, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error during login:', error);
        throw error;
    }
}


async function getMasterProject() {
    const payload = {
        role_id: 1,
        user_id: 1
    };

    try {
        const response = await axios.post(GET_MASTER_PROJECT_URL, payload, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error during login:', error);
        throw error;
    }
}

async function getTaList() {
    const payload = {
        role_id: 1,
        user_id: 1
    };

    try {
        const response = await axios.post(API_URL + 'get_ta_user.php', payload, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error during login:', error);
        throw error;
    }
}

async function deleteProject(projectId) {
    try {
        const response = await axios.delete(`${API_URL}delete_project.php`, {
            data: { project_id: projectId }, // Send the payload in the data field
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error during delete:', error);
        throw error;
    }
}

async function deleteProjectDoc(doc_id) {
    try {
        const response = await axios.delete(`${API_URL}delete_doc.php`, {
            data: { doc_id: doc_id }, // Send the payload in the data field
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error during delete:', error);
        throw error;
    }
}


async function getZeList() {
    const payload = {
        role_id: 1,
        user_id: 1
    };

    try {
        const response = await axios.post(API_URL + 'get_ze_user.php', payload, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error during login:', error);
        throw error;
    }
}


async function addMasterProject(masterProjectName) {
    const payload = {
        master_project_name: masterProjectName
    };

    try {
        const response = await axios.post(API_URL + 'add_master_project.php', payload, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error during login:', error);
        throw error;
    }
}


async function getProjectProcessStatus(projectId) {
    const payload = {
        project_id: projectId
    };

    try {
        const response = await axios.post(API_URL + 'get_project_proccess_status.php', payload, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error during login:', error);
        throw error;
    }
}



async function addProject(
    projectName,
    userId,
    roleId,
    masterPid,
    totalAmount,
    percentageOfWorkDone,
    valueOfWorkDone,
    valueOfStockAtDate,
    selfDeclaration1,
    selfDeclaration2,
    selfDeclaration3,
    selfDeclaration4,
    raFiles,
    geoTaggingFiles,
    testingReports, zonalEngineer, estimatedCost, entrustingDepartment, projectDescription, dateCompletion, mtrDocComment, mtrDocFlag
) {
    console.log({
        projectName,
        userId,
        roleId,
        masterPid,
        totalAmount,
        percentageOfWorkDone,
        valueOfWorkDone,
        valueOfStockAtDate,
        selfDeclaration1,
        selfDeclaration2,
        selfDeclaration3,
        selfDeclaration4,
        raFiles,
        geoTaggingFiles,
        testingReports, zonalEngineer, estimatedCost, entrustingDepartment, projectDescription, dateCompletion, mtrDocComment, mtrDocFlag
    });
    // return '';
    const formData = new FormData();

    formData.append('project_name', projectName);
    formData.append('estimated_cast', estimatedCost)

    formData.append('entrusting_department', entrustingDepartment);
    formData.append('project_description', projectDescription);
    formData.append('date_completion', dateCompletion);
    formData.append('mtr_doc_comment', mtrDocComment);
    formData.append('mtr_doc_flag', mtrDocFlag ? 'Yes' : 'No');

    formData.append('user_ze_ta', zonalEngineer);
    formData.append('total_amount', totalAmount);
    formData.append('user_id', userId);
    formData.append('role_id', roleId);
    formData.append('master_pid', masterPid);
    formData.append('longitude', 'abcd');
    formData.append('latitude', 'pqrs');
    formData.append('percentage_of_work_done', percentageOfWorkDone);
    formData.append('value_of_work_done', valueOfWorkDone);
    formData.append('value_of_stock_at_site', valueOfStockAtDate);
    formData.append('self_declaration1', selfDeclaration1 ? 'true' : 'false');
    formData.append('self_declaration2', selfDeclaration2 ? 'true' : 'false');
    formData.append('self_declaration3', selfDeclaration3 ? 'true' : 'false');
    formData.append('self_declaration4', selfDeclaration4 ? 'true' : 'false');

    // Handle files correctly
    raFiles.forEach(file => {
        formData.append('ra_files[]', file);
    });
    geoTaggingFiles.forEach(file => {
        formData.append('geo_tag_files[]', file);
    });
    testingReports.forEach(file => {
        formData.append('testing_report[]', file);
    });

    console.log("Domain : " + ADD_PROJECT_URL)
    try {
        const response = await axios.post(ADD_PROJECT_URL, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        console.log(response.data);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || error.message);
    }
}





async function updateProject(
    projectId,
    projectName,
    userId,
    roleId,
    masterPid,
    totalAmount,
    percentageOfWorkDone,
    valueOfWorkDone,
    valueOfStockAtDate,
    selfDeclaration1,
    selfDeclaration2,
    selfDeclaration3,
    selfDeclaration4,
    raFiles,
    geoTaggingFiles,
    testingReports, zonalEngineer, estimatedCost, entrustingDepartment, projectDescription, dateCompletion, mtrDocComment, mtrDocFlag
) {
    console.log({
        projectName,
        userId,
        roleId,
        masterPid,
        totalAmount,
        percentageOfWorkDone,
        valueOfWorkDone,
        valueOfStockAtDate,
        selfDeclaration1,
        selfDeclaration2,
        selfDeclaration3,
        selfDeclaration4,
        raFiles,
        geoTaggingFiles,
        testingReports, zonalEngineer, estimatedCost, entrustingDepartment, projectDescription, dateCompletion, mtrDocComment, mtrDocFlag
    });
    // return '';
    const formData = new FormData();

    formData.append('project_id', projectId);
    formData.append('project_name', projectName);
    formData.append('estimated_cast', estimatedCost)

    formData.append('entrusting_department', entrustingDepartment);
    formData.append('project_description', projectDescription);
    formData.append('date_completion', formatDate(dateCompletion));
    formData.append('mtr_doc_comment', mtrDocComment);
    formData.append('mtr_doc_flag', mtrDocFlag ? 'Yes' : 'No');

    formData.append('user_ze_ta', zonalEngineer);
    formData.append('total_amount', totalAmount);
    formData.append('user_id', userId);
    formData.append('role_id', roleId);
    formData.append('master_pid', masterPid);
    formData.append('longitude', 'abcd');
    formData.append('latitude', 'pqrs');
    formData.append('percentage_of_work_done', percentageOfWorkDone);
    formData.append('value_of_work_done', valueOfWorkDone);
    formData.append('value_of_stock_at_site', valueOfStockAtDate);
    formData.append('self_declaration1', selfDeclaration1 ? 'true' : 'false');
    formData.append('self_declaration2', selfDeclaration2 ? 'true' : 'false');
    formData.append('self_declaration3', selfDeclaration3 ? 'true' : 'false');
    formData.append('self_declaration4', selfDeclaration4 ? 'true' : 'false');

    // Append RA files
    // raFiles.forEach((fileUri) => {
    //     const fileName = getFileName(fileUri);
    //     formData.append('ra_files[]', {
    //         uri: fileUri,
    //         type: 'application/pdf',
    //         name: fileName,
    //     });
    // });
    // console.log("Nilesh")
    // console.log(raFiles);
    // raFiles.forEach((fileUri) => {
    //     const fileName = getFileName(fileUri);
    //     console.log("Nilesh " + fileName)
    //     const fileType = getMimeType(fileUri);
    //     console.log("Nilesh " + fileType)
    //     formData.append('ra_files[]', {
    //         uri: fileUri,
    //         type: fileType,
    //         name: fileName,
    //     });
    // });
    if (Array.isArray(raFiles)) {
        raFiles.forEach((file) => {
            const fileUri = file.uri;
            const fileName = file.name || getFileName(fileUri);
            const fileType = file.type || getMimeType(fileUri);

            // console.log("Nilesh File URI: " + fileUri);
            // console.log("Nilesh File Name: " + fileName);
            // console.log("Nilesh File Type: " + fileType);

            formData.append('ra_files[]', {
                uri: fileUri,
                type: fileType,
                name: fileName,
            });
        });
    }
    // console.log("Nilesh now we are done.")
    // appendFilesToFormData(raFiles, formData, 'ra_files[]');

    // Append Geo-Tagging files
    geoTaggingFiles.forEach((fileUri) => {
        const fileName = getFileName(fileUri);
        formData.append('geo_tag_files[]', {
            uri: fileUri,
            type: 'image/jpeg',
            name: fileName,
        });
    });

    // Append Testing Reports
    // testingReports.forEach((fileUri) => {
    //     const fileName = getFileName(fileUri);
    //     formData.append('testing_report[]', {
    //         uri: fileUri,
    //         type: 'image/jpeg',
    //         name: fileName,
    //     });
    // });

    if (Array.isArray(testingReports)) {
        testingReports.forEach((file) => {
            const fileUri = file.uri;
            const fileName = file.name || getFileName(fileUri);
            const fileType = file.type || getMimeType(fileUri);

            // console.log("Nilesh File URI: " + fileUri);
            // console.log("Nilesh File Name: " + fileName);
            // console.log("Nilesh File Type: " + fileType);

            formData.append('testing_report[]', {
                uri: fileUri,
                type: fileType,
                name: fileName,
            });
        });
    }
    console.log("Domain : " + UPDATE_PROJECT_URL)
    try {
        const response = await axios.post(UPDATE_PROJECT_URL, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        console.log(response.data);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || error.message);
    }
}

async function updateStatus(projectId, userId, roleId, comments, statusFlag, totalAmount, mobilizationAdvance, valueOfWorkDone, valueOfStockAtDate, taId, scanDocs) {
    console.log({ projectId, userId, roleId, comments, statusFlag, totalAmount, mobilizationAdvance, valueOfWorkDone, valueOfStockAtDate, taId, scanDocs });
    const formData = new FormData();
    formData.append('project_id', projectId);
    formData.append('user_id', userId);
    formData.append('role_id', roleId);
    formData.append('comment', comments);
    formData.append('status_flag', statusFlag);
    formData.append('longitude', 'apcdef');
    formData.append('latitude', 'ghijk');
    formData.append('total_amount', totalAmount);
    formData.append('mobilization_advance', mobilizationAdvance);
    formData.append('value_of_work_done', valueOfWorkDone);
    formData.append('value_of_stock_at_site', valueOfStockAtDate);
    formData.append('user_id_ta', taId);

    // Append RA files
    scanDocs.forEach((fileUri) => {
        const fileName = getFileName(fileUri);
        formData.append('scan_doc[]', {
            uri: fileUri,
            type: 'image/jpeg',
            name: fileName,
        });
    });

    try {
        const response = await axios.post(PUT_PROJECT_STATUS_URL, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || error.message);
    }

}
export { getProject, getTaskType, addProject, deleteProjectDoc, updateProject, deleteProject, addMasterProject, getContractorList, getZeList, getMasterProject, updateStatus, getTaList, getProjectProcessStatus };
